import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import extractMutations from "./mutations"
import extractActions from "./actions"

const baseURI = "/companies/price_tables"

const store = new StoreItemsModule({
  baseURI: baseURI,
  presetActions: ["fetch", "delete"],
  withSorting: true,
  withPagination: true
})

store.mergeState({
  item: {}
})

store.mergeMutations(extractMutations())
store.mergeActions(extractActions({ baseURI }), withLoading)

export default store
